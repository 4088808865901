import { jsx as _jsx } from "react/jsx-runtime";
import { IconProfile } from '@klarna/bubble-ui';
import { Tooltip } from '@klarna/mp-ui';
import { TopBarActionButton } from '@merchant-portal/framework';
import { hooks as coreHooks } from 'mage-core';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
const getDisplayName = (givenName, familyName) => {
    return givenName && familyName
        ? `${givenName} ${familyName.charAt(0)}.`
        : givenName
            ? `${givenName}`
            : '?';
};
function UserMenuIcon({ toggled }) {
    const t = i18nHooks.useTranslator();
    return toggled ? (_jsx(Button, { toggled: toggled })) : (_jsx(Tooltip, { text: t('core.usermenu.tooltip'), children: _jsx(Button, { toggled: toggled }) }));
}
function Button({ toggled }) {
    const tokenHelper = coreHooks.useTokenHelper();
    const familyName = tokenHelper.getFamilyName();
    const givenName = tokenHelper.getGivenName();
    const isDeepLinkUser = tokenHelper.isDeepLinkUser();
    const partnerAccounts = useSelector(coreSelectors.getPartnerAccounts);
    const displayName = isDeepLinkUser && (partnerAccounts === null || partnerAccounts === void 0 ? void 0 : partnerAccounts.length)
        ? partnerAccounts[0].name
        : getDisplayName(givenName, familyName);
    const actionButtonProps = {
        id: 'header-usermenu-icon',
        toggled: toggled,
        Icon: IconProfile,
        label: displayName
    };
    return _jsx(TopBarActionButton, Object.assign({}, actionButtonProps));
}
Button.propTypes = {
    toggled: PropTypes.bool
};
UserMenuIcon.propTypes = {
    toggled: PropTypes.bool
};
export default UserMenuIcon;
